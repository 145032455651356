<div class="container">
    <div class="content">
        <div class="title-container">
            <img src="../../../assets/captain_avatar_au-nz-uk.png" alt="Flight Centre AU" />

            <div class="title-text-container">
                <span class="title-text">Flight Centre</span>
                <!-- <span class="subtitle-text">Your FX companion</span> -->
            </div>
        </div>
        <div class="top-banner">
            <img src="../../../assets/DD-9257_Deals Page Header_desktop_1920x550.jpg" alt="Martin Garrix" />
        </div>

        <div class="page-content">
            <div class="message-container">
                <span class="message-title-text">Payment Error</span>
            </div>

            <div class="success-text-container">
                <p class="personal-field-title">An error occured while processing your payment.</p>
                <p class="personal-field-title">Click on the button below to place a new order.</p>
            </div>
    
            <div class="btn-container">
                <button class="btn-style" mat-raised-button color="custom-color" (click)="navigateToHome()">
                    New Order
                </button>
            </div>
        </div>
    </div>
</div>
import { Routes } from '@angular/router';
import { CancelPageComponent } from './components/cancel-page/cancel-page.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { SuccessPageComponent } from './components/success-page/success-page.component';
import { TestPageComponent } from './components/test-page/test-page.component';

export const routes: Routes = [
    { path: 'home', component: HomePageComponent},
    { path: 'success', component: SuccessPageComponent},
    { path: 'error', component: ErrorPageComponent},
    { path: 'cancel', component: CancelPageComponent},
    { path: '**', redirectTo: '/home', pathMatch: 'full' },
  ];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RatesService {

  constructor(private http: HttpClient) { }

  getFxRate(baseCurrency: string, quoteCurrency: string)
  {    
    let todayDate = new Date();
    let yesterDate = new Date();
    yesterDate.setDate(yesterDate.getDate()-1)

    console.log(todayDate)
    console.log(yesterDate)

    let todayDateStr = todayDate.toISOString();
    let yesterDateStr = yesterDate.toISOString();

    let todayDateStrformatted = todayDate.toISOString().substring(0, todayDateStr.indexOf('T'));
    let yesterDateStrformatted = yesterDate.toISOString().substring(0, todayDateStr.indexOf('T'));

    console.log(todayDateStr.substring(0, todayDateStr.indexOf('T')))
    console.log(yesterDateStr.substring(0, yesterDateStr.indexOf('T')))

    return this.http.get<any>(
      'https://fxds-public-exchange-rates-api.oanda.com/cc-api/currencies?base='
      + baseCurrency + '&quote=' + quoteCurrency + '&data_type=general_currency_pair&start_date=' + yesterDateStrformatted + '&end_date=' + todayDateStrformatted)
  }
}
